export const post = async (url: string, data: {[key: string]: string}): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}