import * as React from "react";
import { Map, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import house from "../../../static/home.png";
import { NAME } from "../../consts/common"
import * as styles from "./mapComponent.module.scss";

const position = [50.877, 15.6215];
let houseIcon;
if (typeof window !== "undefined") {
  houseIcon = L.icon({
    iconUrl: house,
    iconSize: [30, 30],
  })
}

const MapComponent: React.FC = () => {
  if (typeof window !== 'undefined') {
    return (
      <Map center={position} zoom={11} className={styles.leafletContainer}>
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/${process.env.GATSBY_MAPBOX_USER}/ckfhy3idb0ohl19qfs2k0xc4x/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.GATSBY_MAPBOX_API_KEY}`}
          attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>"
        />
        <Marker position={position} icon={houseIcon}>
          <Tooltip className={styles.mapTooltip} direction="top" offset={[0, -10]} permanent>
            <div className={styles.mapTooltip__custom}>{NAME}</div>
          </Tooltip>
        </Marker>
      </Map>
    )
  }
}

export default MapComponent;
