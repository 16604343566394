import * as React from "react";
import { useRef, useState } from "react"
import { Link, useIntl } from "gatsby-plugin-intl"
import * as styles from './contactForm.module.scss';
import { post } from "../../services/fetchService"
import ReCAPTCHA from 'react-google-recaptcha'

const ContactForm: React.FC = () => {
  const [fromName, setFromName] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const intl = useIntl();
  const recaptchaRef = useRef(ReCAPTCHA);

  const sendEmail = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);
    if (formErrors.length === 0) {
      setLoading(true);
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      try {
        await post('/.netlify/functions/sendEmail', {fromName, fromEmail, message, token})
        setShowSuccessMessage(true);
        setFromName('');
        setFromEmail('');
        setMessage('');
      } catch (e) {
        console.error(e);
        setErrors([intl.formatMessage({id: 'contact__form--error'})])
      } finally {
        setLoading(false);
      }
    }
  }

  const validateForm = () => {
    const formErrors = [];
    const email = fromEmail.trim();
    const text = message.trim();

    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    !email && formErrors.push(intl.formatMessage({id: 'contact__form--no-email'}));
    email && !email.match(emailRegex) && formErrors.push(intl.formatMessage({id: 'contact__form--wrong-email'}));
    !text && formErrors.push(intl.formatMessage({id: 'contact__form--no-message'}));

    return formErrors;
  }

  return (
    <form className={styles.contactForm} onSubmit={sendEmail}>
      <label className={styles.formLabel}>
        {intl.formatMessage({id: 'contact__form--sender'})}:
      </label>
      <input
        value={fromName}
        onChange={(e) => {setFromName(e.target.value)}}
        className={styles.formInput}
        name="from_name"
      />
      <label className={styles.formLabel}>
        {intl.formatMessage({id: 'contact__form--email'})}:
      </label>
      <input
        value={fromEmail}
        onChange={(e) => {setFromEmail(e.target.value)}}
        className={styles.formInput}
        name="reply_to"
      />
      <label className={styles.formLabel}>
        {intl.formatMessage({id: 'contact__form--message'})}:
      </label>
      <textarea
        value={message}
        onChange={(e) => {setMessage(e.target.value)}}
        className={`${styles.formInput} ${styles.formTextarea}`}
        name="message"
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
      />
      <div className={styles.messageContainer}>
        {errors.length > 0 && (
          errors.map(err => (
            <div className={styles.error}>{err}</div>
          ))
        )}
        {showSuccessMessage && (
          <div className={styles.successMessage}>{intl.formatMessage({id: 'contact__form--success'})}</div>
        )}
      </div>
      <input type="submit" value={intl.formatMessage({id: 'contact__form--submit'})} className={styles.submitButton} disabled={loading} />
      <div className={styles.privacyPolicyInfo}>
        <span>{intl.formatMessage({id: 'contact__form--privacy-policy-description'})}</span>
        <Link to="/privacyPolicy/">{intl.formatMessage({ id: 'contact__form--privacy-policy-link' })}</Link>
      </div>
    </form>
  )
}

export default ContactForm;
