import * as React from "react";
import { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { GoMailRead } from "@react-icons/all-files/go/GoMailRead";
import { FiPhoneCall } from "@react-icons/all-files/fi/FiPhoneCall";
import { AiOutlineForm } from "@react-icons/all-files/ai/AiOutlineForm"
import Layout from "../components/Layout/Layout";
import MapComponent from "../components/MapComponent/MapComponent";
import { ADDRESS_LINE_1, ADDRESS_LINE_2, EMAIL, NAME, PHONE_1, PHONE_2 } from "../consts/common"
import * as styles from "./contact.module.scss";
import CalendarButton from "../components/CalendarButton/CalendarButton";
import CalendarModal from "../components/CalendarModal/CalendarModal";
import ContactForm from "../components/ContactForm/ContactForm";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"


const Contact: React.FC = () => {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const intl = useIntl();

  return (
    <Layout>
      <div className={styles.contactContainer}>
        <div className={styles.contactRow}>
          <div className={styles.mapContainer}>
          {
            typeof window !== 'undefined' && (
              <MapComponent />
            )
          }
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.contactInfo__header}>{NAME}</div>
            <div>{ADDRESS_LINE_1}</div>
            <div>{ADDRESS_LINE_2}</div>
            <CalendarButton
              onClick={() => {
                setCalendarVisible(true)
                document.body.classList.add('scrollDisabled');
              }}
              className={styles.contactInfo__button}
            />
          </div>
        </div>
        <div className={styles.contactRow}>
          <div className={styles.formInfo}>
            <div className={styles.formInfo__content}>
              <div className={styles.contactInfo__header}>{intl.formatMessage({id: 'contact__info--header'})}</div>
              <div className={styles.formInfo__row}>
                <AiOutlineForm className={styles.contactIcon}/>
                <div>{intl.formatMessage({id: 'contact__info--form'})}</div>
              </div>
              <div className={styles.formInfo__row}>
                <FiPhoneCall className={styles.contactIcon}/>
                <div>
                  <div>{intl.formatMessage({id: 'contact__info--phone'})}</div>
                  <div>{PHONE_1}</div>
                  <div>{PHONE_2}</div>
                </div>
              </div>
              <div className={styles.formInfo__row}>
                <GoMailRead className={styles.contactIcon}/>
                <div>
                  <div>{intl.formatMessage({id: 'contact__info--email'})}</div>
                  <div>{EMAIL}</div>
                </div>
              </div>
              <div className={styles.formInfo__row}>
                <a
                  href="https://www.facebook.com/naszegorskie"
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles.contactIcon}
                ><FaFacebookSquare color='#fff'/></a>
                <div>{intl.formatMessage({id: 'contact__info--social'})}</div>
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <ContactForm />
          </div>
        </div>
      </div>
      {calendarVisible && <CalendarModal onClose={() => {setCalendarVisible(false)}} /> }
    </Layout>
  )
}

export default Contact;
