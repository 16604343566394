// extracted by mini-css-extract-plugin
export const contactContainer = "contact-module--contactContainer--1rR9x";
export const contactRow = "contact-module--contactRow--1-xAM";
export const contactInfo = "contact-module--contactInfo--3WBAw";
export const contactInfo__header = "contact-module--contactInfo__header--3W0cQ";
export const contactInfo__button = "contact-module--contactInfo__button--Ig0Ej";
export const mapContainer = "contact-module--mapContainer--2aT_1";
export const formInfo = "contact-module--formInfo--2NdwX";
export const formInfo__row = "contact-module--formInfo__row--2JuTU";
export const formInfo__content = "contact-module--formInfo__content--vqn-7";
export const contactIcon = "contact-module--contactIcon--h8Nis";
export const formContainer = "contact-module--formContainer--1wKVe";